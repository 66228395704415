// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//   "private_key" : "-----BEGIN RSA PRIVATE KEY-----\n"
// + "MIIEpAIBAAKCAQEAwVxHTk0/UZhxAvMcXDTKIrneczgosEpC80LTxokDn6VGhvtl\n"
// + "TSAHuGFXmjXTmWJqR2uPvC1JmKb4WA0Vcm8gW25cZ/sIpcwahx/1ELnekZl+FecI\n"
// + "oyJszrDQyhXxVbevF1GAVT1c98elSBIr/VyKlR2Z59FUgrbGOqCvOFBgEuxNWGKD\n"
// + "nf2qwOg3RYmHDXCtVp6Tf1Nqsbk0zEbd/CFmFNgUyMVEt4Eq3Mx+56/64apEswg+\n"
// + "CfKJyt8qOxNbHPhVgw/UGtN+CJ8kIxf3Q9rwrIXU/RIBqO3SkgsC27YPho2hn7K9\n"
// + "CnmdBYj74aZGcP7GGHZc6q9yjjwv/hHeSjsuzQIDAQABAoIBAQCw36WGddj+3bA6\n"
// + "34HIwq1ImLdotC1cHYIinbW0/W5oIbpjDSG8LBmDyt9ke+pke4b2b+jaXJ7e2ZWA\n"
// + "je9Zb1es60XyA8sERrDZfB0jQLLcgwi4Jk9bUskvRMSopuvM2ip67R20W15odaK2\n"
// + "S5EbwQQvpRU83dote93WkVfW7ocDprSaNZ705Ozsuk8k4t/8fcczfzsZrg2GJvdg\n"
// + "aa9fc6BZai/Y2AgrJttZCK4AOBngf/XSagRmlUxYJX4ZtupNrb1gmlOlemhzBRLp\n"
// + "eOjPA9gBTFUXOyfyCPtns9Eae1Mh1/gj/605zk0L+h6DMhqRAUiWzV10nMZt5iVv\n"
// + "Hw0shfjtAoGBAPrmhXkzjJn1kNDmLwqx7M0u5+lxN4TyNvVSeuaeaTw6ZrNuRaEp\n"
// + "pRoXaVZsOWWji0RvILMxcMPTJpTXfmWdgRm6ZX2bg4b4Zb7ZL8/Ul5nvgxkkkUCR\n"
// + "OpDDmDhrsgd1wIBuVhy/DY+W3A+2+P3bMgDc7eDkek0wlw4YJUkWYHbfAoGBAMVK\n"
// + "XdPtys6A8pQoYtj5cLQKswTn8u5/PjUB9Yr0myXODFb1oExNewRBWYLqLtq58pR9\n"
// + "auFhdxSvNTPawvIa0acNXxcFKzlJCcuCO5pchsZ3O4tMb6jWI3/6odiTQs1fu6bC\n"
// + "YKo2Y3ykeNKkTQPWUgfycEz5ZmY91UmBBgUMNmvTAoGBAIkYIOKDGlad6c1bmWS8\n"
// + "p+nNsYbLCE0qlIEj2mfdMx9nwepVXNKdEMva8wH4DLzOyjBQkIuZfkZiP/igbz5S\n"
// + "+9H1GbOyoAHGBMJtpkRno5bHWtkINB4VvOyRE8HwhsywuMj0gTUf5+cHxaMIlbEQ\n"
// + "bpguIAT8kx9OZ5uZ8fWntnn9AoGAQaZtcog+ZFkkX4jQ28KlbkPhLlDA1fwhhyIP\n"
// + "wx2CQyI9mPJDVSCzPsdAMgAbd01JG4BggH8eURO8cHTrSgUCnsQkUFS3/WgAimfb\n"
// + "zZHch05F0iStQ1F+nqeYE6ltIUop92PXJszSbjeMvQtPVWeMBHj4RaT4Hi2VO9Bj\n"
// + "YGqf8WECgYASbc1/mTJj/REndc1DENG97f+yHNZyBYKeyJ82LBkrkNNWamJm1oYP\n"
// + "YKA835YYmshOCPEEKcPb1fCOg9VDNrqMmOygLY/ah/K1PYoliGZvUmGofx9xe6SA\n"
// + "OxU2d3oHUCR8w+avp7V4/mxb9QTIeBu+iR7AuK/3yQ/Lla5VeFZ8IA==\n"
// + "-----END RSA PRIVATE KEY-----",
 "iframe_url":'reveal-card-details/',
 "iframe_show_pin_url":'get-card-pin/',
 "iframe_set_pin_url":'change-card-pin/',
 "api_url":'https://api.dev.platform.nm-2.nymcard.com/',
 "sandbox_url":'https://api.sand.platform.nymcard.com/',
 "stag_url":'https://api.stag.platform.nymcard.com/',
 "oracle_stag_url":'https://api.stg.platform.ae-1.nymcard.com/',
 "oracle_prod_url":'https://api.platform.ae-1.nymcard.com/',
 "alibaba_prod_url":"https://api.platform.my-1.nymcard.com/",
 "source_id":'default_pci_source',
 "tenantDetails":[{"tenantID":'tenant1',"clientName":'Tenant1'},
                  {"tenantID":'tenant2',"clientName":'Tenant2'},
                  {"tenantID":'tenant4',"clientName":'Beyond'},
                  {"tenantID":'tenant5',"clientName":'Bayblos'},
                  {"tenantID":'tenant7',"clientName":'Neo'}],
 "env_type":"dev",

  //"iframe_url":'https://showcardwidget.dev.platform.nymcard.com/card-activate/',



"card_reveal_private_key" : "-----BEGIN RSA PRIVATE KEY-----\n"
+"MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCMqJ6trwuVPe6vG0qIoCDeh7bn03mHjnwvNrFHrvVnEqM+pK6htaI5ATd0qInJnehthZL0zphPDRMBqqrrCHOEAX1JteqWnEH52c2/WSGK43ItS2wZx3+0hKPmGJhtFN+7pBrQMmJPare1cuRXhCRZJt5Cb5wKOlE+pt9p/7wyvumfq8fjCXjAHFe2RwKCMQguO7sxGDOX9oSCJ+oU/9cmoofKB55g6/yf6t9/cbSi99vmZufB1nsZwr3m7WqqD5S36lU2EIOtBOLiNII1XUD45eTk9m0aSJjPL0hwl7A2B8B7vXRBHNZfUrHon5Kg/WC7IKqOernOs2lA2y/Hw90xAgMBAAECggEAI/pBVBWQ9eR4d6uYXefvYFwASb3KuQSV+RGZOMekl2+p96zxygUGcGRCRgGR9NMCD3l6BEoPpTCn6uY3f5SSz3H+FOuGDG39ZiXyvX3T2Y2ht7ox9UoU/RxsO7+QxV3weDNadGRexwd549x2UXhIP9F0SjP4CpmuDENso9U40xWl4eGVpLPvY+UoEOOJ2xVrDCjYaqdHcf0UW3h0woRGHGhSwrXPJV8xREVBWBs+hC6kCGB7bj8IMU9yY1fan6xu8Mp/GuLWpkcJzU+k2NVy3jjE9t37k6T1WifN6qf4xs2uGbAp7HQEFpTgarr1f3KRmDHAsAPykOWl+7p8jS++0QKBgQDbGEMI2OqOhRp+67P22EUK9iLUBOzmDarnM/z6KqFx6c1kYXc1zrw8ZEG8EXpWADK5WVW8jjp/FLUxEImMx3KFSiQnLmt5lwIJTteoyNcxf01aEYzUQgzO9fsvdzMrtOomyunIAKuE5HAHVw54tl7uGmXL4vDZR1DYatLjCJdN1QKBgQCkWg9t3avTZt/iE2lL6u3tOIbKIiPo7Qy0dtLsNHONcAwdLVTlkT/xyGG36TsPSuy3TCOay9ibRKJ4KanEFUys/8PkAH+VvoJGqADVsLkC8SIbcHuoH0ESkhiPwCvShApzealMu6AWWqHOqWZ1hn8YIZZnGJGsCtNVg9FJaEIT7QKBgQDHTqDckYd+svLRdtW1vjPArvwr53ciKFjKDm8ZnGJVYB0qLcfmOPTf80whK70gMvnxxqTJ7JCmPk4+Hg8i8+Tn//Q3eHDooUiG8yQYNOwd6PKS9tgn1xYTx40DNupj3iYLuBdHi+R79ds7siRGB5EABR+JhB8ZaVjQJw1E3vHVKQKBgGGbgxJS1ltk+43+zUhjLA74xUSC5iTNs//xme7ib8YOR4OEiqkUjI+B3FcnUwYQD2oE9BhY/Dmql3sZ81Ma+0C7XZGpohxC2ogv+t7uZ9KRdxoowafld/YZgjDNhrkt5i8SkDOcMsVT6kkcXldqG00vlW2BL/KnMz/UW5VnL+c5AoGBAJUEEbmvwdu1pkfFPe+S5GxUbD5lbd8L/NivEP8l1GLo3xLmkx3HGpWXrqiKYsUhBMQs29XFVRlvbRtEPdlsmfD0bjKDgWZ0JuXuBrWUc0Au5N5X8a9cJidyfwlUzUU/ve18nRPyRBpWBd2DxYH4O1wbQSKojkFqS68K8FJFd7Bz\n"
+"-----END RSA PRIVATE KEY-----",


"private_key" : "-----BEGIN RSA PRIVATE KEY-----\n"
+ "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+VZff0TaTYJhEoZkXCVkscTunXf3ORpCQLLhjHVPvQgYW0cgoGY+eNv27MObFNbUCKGM7iITFhmHU4nPv89huCNG58Qnby7MloIAkYollpe2CsqqAsCDEmvQgdD4oW2KWpuup5wU7RoF2m3FjeO8FuWDZx9v8BLlTdGZTAXpHOYm5NJ5IruzGd7OF565sW6yfGuineKKimom/2IaRVr3s/eMCIX+1U3UeqawAdqJehuc5XbFew18dQnQnzFbrVWnLtK+0THRItxVv+QxpSy1A10gi8R5LESZ2ZQePclqQZaEULv2lBc/+0Np4fvWZVkkUeo74x9ubn4S0dTs7N/MrAgMBAAECggEATyDqiQRDejXYWAAO00LxrcZNlKFA2TcA9s+AwMP6lyeNvILuga1Oxen6xo3oAuj3HbSIvMFe4XeiyIx0wrdPrT9BYdpRzOz8x5HYdEf68yVufr2koOeYV+RLxgpNpR2I+TTgGLyQsjn3rMWm+EHYrTqKhz/NtxKHsahcufmNSnprMlFnhR81HkwMTJVFduD8awNQgywOoEKky9LtsdRi59uH+PZfxZM1AubuChIezOQwiSu+IiL+ypgTv7gSyYeChsZJPFN7oyTjU0gx2IKpUOiPMxPsOVY6SxXDbQFoakyA/tuH3a3xWi4TsbNk3tIgyZbIkPPvHfjeTA6IZCxeUQKBgQDuPTA6vt78ilJG0TidMaVPIxJ4/mskVVfPcSZplXKGNbJDDUD5vaTU3zMLV3CXdcvu8d9gQDdOaFuWIbVZY0NbwiZPVy7E6eKTpgAzsEbVXw/P4GSFSMuUErOOKFNeGOgj+6b81j/XNk3YmPkKhN+bEc4ADpEU7tKU9/bXEYMutQKBgQDMhiPnc5MlFP3008nJ6cp1zRDD79EsesMo0TnQHlrTAjuY2T/6fuErlnNxY7ynE+xcJQbYcJmfyndyoX/OVJQOPcoiU5AgjBgwweCYOQpW6Kh7LRQXv1TQvzVjM9lssZ+Lwmda5xxFympCgANiqyn+iSbfTvh7E6EOIfYb+ifmXwKBgDFL9gPh5RBKqvW+a0GTuUojzrm8o3z0L+Rb3H/yja0ZxVY8ktpalJX7zXsmT8T3eghqgjdPcmJGE9WACh+B+7kr9nNqbeIPJ3Q34EjRzAZw0jvIieROiNmN+RuMWpvY+YgglZAx4SnuMbOFC9tSXVagG4lFel3jgS2UFb6w//0VAoGAcOVazFgv0ThVNt/xc1NqPryDlKS8giEBuXZEw2z+FZwV6bmCZ3PCN51E/EJh0Jhe0D5gGPmO03WfmdcsBWkKkRHqkiguVsj+UWuz3lMeMfsbhWLMxI8I8nGZtrjYO/eQakVVaAhKwvuIzEx9kemJtMdgfcPJxaaAMJzujWL4w2ECgYBc09fYu8CamUpVDTgCUGizV4fBzeBiygmxUAyg1wcV3E5HHSrCmZRL0jRi0RqlURprgb2R3wyvD9nDobDY6AZYEqTw8DKP2U55Fxo1aoua1TT/uQe0zRnDugAu/BNAboe0FWMNd004NiM73TC/x8yUP+wo2B3o0+G7OCiUqvqGMw==\n"
+ "-----END RSA PRIVATE KEY-----",

// "private_key" : "-----BEGIN RSA PRIVATE KEY-----\n"
// + "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+VZff0TaTYJhEoZkXCVkscTunXf3ORpCQLLhjHVPvQgYW0cgoGY+eNv27MObFNbUCKGM7iITFhmHU4nPv89huCNG58Qnby7MloIAkYollpe2CsqqAsCDEmvQgdD4oW2KWpuup5wU7RoF2m3FjeO8FuWDZx9v8BLlTdGZTAXpHOYm5NJ5IruzGd7OF565sW6yfGuineKKimom/2IaRVr3s/eMCIX+1U3UeqawAdqJehuc5XbFew18dQnQnzFbrVWnLtK+0THRItxVv+QxpSy1A10gi8R5LESZ2ZQePclqQZaEULv2lBc/+0Np4fvWZVkkUeo74x9ubn4S0dTs7N/MrAgMBAAECggEATyDqiQRDejXYWAAO00LxrcZNlKFA2TcA9s+AwMP6lyeNvILuga1Oxen6xo3oAuj3HbSIvMFe4XeiyIx0wrdPrT9BYdpRzOz8x5HYdEf68yVufr2koOeYV+RLxgpNpR2I+TTgGLyQsjn3rMWm+EHYrTqKhz/NtxKHsahcufmNSnprMlFnhR81HkwMTJVFduD8awNQgywOoEKky9LtsdRi59uH+PZfxZM1AubuChIezOQwiSu+IiL+ypgTv7gSyYeChsZJPFN7oyTjU0gx2IKpUOiPMxPsOVY6SxXDbQFoakyA/tuH3a3xWi4TsbNk3tIgyZbIkPPvHfjeTA6IZCxeUQKBgQDuPTA6vt78ilJG0TidMaVPIxJ4/mskVVfPcSZplXKGNbJDDUD5vaTU3zMLV3CXdcvu8d9gQDdOaFuWIbVZY0NbwiZPVy7E6eKTpgAzsEbVXw/P4GSFSMuUErOOKFNeGOgj+6b81j/XNk3YmPkKhN+bEc4ADpEU7tKU9/bXEYMutQKBgQDMhiPnc5MlFP3008nJ6cp1zRDD79EsesMo0TnQHlrTAjuY2T/6fuErlnNxY7ynE+xcJQbYcJmfyndyoX/OVJQOPcoiU5AgjBgwweCYOQpW6Kh7LRQXv1TQvzVjM9lssZ+Lwmda5xxFympCgANiqyn+iSbfTvh7E6EOIfYb+ifmXwKBgDFL9gPh5RBKqvW+a0GTuUojzrm8o3z0L+Rb3H/yja0ZxVY8ktpalJX7zXsmT8T3eghqgjdPcmJGE9WACh+B+7kr9nNqbeIPJ3Q34EjRzAZw0jvIieROiNmN+RuMWpvY+YgglZAx4SnuMbOFC9tSXVagG4lFel3jgS2UFb6w//0VAoGAcOVazFgv0ThVNt/xc1NqPryDlKS8giEBuXZEw2z+FZwV6bmCZ3PCN51E/EJh0Jhe0D5gGPmO03WfmdcsBWkKkRHqkiguVsj+UWuz3lMeMfsbhWLMxI8I8nGZtrjYO/eQakVVaAhKwvuIzEx9kemJtMdgfcPJxaaAMJzujWL4w2ECgYBc09fYu8CamUpVDTgCUGizV4fBzeBiygmxUAyg1wcV3E5HHSrCmZRL0jRi0RqlURprgb2R3wyvD9nDobDY6AZYEqTw8DKP2U55Fxo1aoua1TT/uQe0zRnDugAu/BNAboe0FWMNd004NiM73TC/x8yUP+wo2B3o0+G7OCiUqvqGMw==\n"
// + "-----END RSA PRIVATE KEY-----",

// "public_key" : "-----BEGIN RSA PUBLIC KEY-----\n"
// +"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqce2Sf8MgdBGO4sv0yiOrBpMkvV3pOVZGtuM4d0rQrA/jXAS1JjSEaUauLJipM2X+JiziUMDgofKPPQKZakMfH3ZnDvV8TgOpQwJ61iI6xGhU+Y3DTPP+BtvdyKvxeTbrQc5Et9LOhFzYCblXN3POdlrrpec4VsEQN9szuQfDRtDEqkNpgjanS5PUKBluwdpkUPdAJuA17wRkYVJd5FsgZF6qmt/GvooLrQMOU4C8yTPFUwYuDNiXXe520JA9adi4YD/WXCkl8uB2vfZjXyqKcNqKoMi3Wm3yI128SBYX2hg/PYt6jO+DrvoxmQJm9iFi3aTWFhuy/2nSY6DNyM/hwIDAQAB\n"
// +"-----END RSA PUBLIC KEY-----"

// "public_key":"-----BEGIN RSA PUBLIC KEY-----\n"
// +"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvlWX39E2k2CYRKGZFwlZLHE7p139zkaQkCy4Yx1T70IGFtHIKBmPnjb9uzDmxTW1AihjO4iExYZh1OJz7/PYbgjRufEJ28uzJaCAJGKJZaXtgrKqgLAgxJr0IHQ+KFtilqbrqecFO0aBdptxY3jvBblg2cfb/AS5U3RmUwF6RzmJuTSeSK7sxnezheeubFusnxrop3iiopqJv9iGkVa97P3jAiF/tVN1HqmsAHaiXobnOV2xXsNfHUJ0J8xW61Vpy7SvtEx0SLcVb/kMaUstQNdIIvEeSxEmdmUHj3JakGWhFC79pQXP/tDaeH71mVZJFHqO+Mfbm5+EtHU7OzfzKwIDAQAB\n"
// +"-----END RSA PUBLIC KEY-----"

"public_key":"-----BEGIN RSA PUBLIC KEY-----\n"
  +"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnoKm9GUmDj2to8ldXHk1fX87NK8S99KAZ600ok+cnr6k0raeNI7BtQP9lhv/5ZeiZnCC6VNihDY9SfIGglDUi4pAGNYOmo0VmlbzTUejKJgktePdhjw5M3YStxidFN0oNVaaw6RQpcfeUGIoF6DiKC9p8hP85R/+C5dKy5Eflw5jrPDAkFZ01INSThZFEzK3f/KXpzGm7Y/9cfkqVlCoq+2wcBiX6B6SQplD7DUKA9SyI/YXKz/aKA1iBMumPyn+VfR9lDTLbYlw2JXsES7BWluAXpXC7yJ2fTfqCzGGmh/4f36EXGrkQnASA9DzNbYwyA3ZXsUIJzRL+38B8QVx1wIDAQAB\n"
  +"-----END RSA PUBLIC KEY-----"
  

// "public_key":"-----BEGIN RSA PUBLIC KEY-----\n"
// +"MIIBCgKCAQEAqce2Sf8MgdBGO4sv0yiOrBpMkvV3pOVZGtuM4d0rQrA/jXAS1JjS\n"
// +"EaUauLJipM2X+JiziUMDgofKPPQKZakMfH3ZnDvV8TgOpQwJ61iI6xGhU+Y3DTPP\n"
// +"+BtvdyKvxeTbrQc5Et9LOhFzYCblXN3POdlrrpec4VsEQN9szuQfDRtDEqkNpgja\n"
// +"nS5PUKBluwdpkUPdAJuA17wRkYVJd5FsgZF6qmt/GvooLrQMOU4C8yTPFUwYuDNi\n"
// +"XXe520JA9adi4YD/WXCkl8uB2vfZjXyqKcNqKoMi3Wm3yI128SBYX2hg/PYt6jO+\n"
// +"DrvoxmQJm9iFi3aTWFhuy/2nSY6DNyM/hwIDAQAB\n"
// +"-----END RSA PUBLIC KEY-----"

// "public_key":"-----BEGIN RSA PUBLIC KEY-----\n"
// +"MIIBCgKCAQEApQLInftM75UA7Xh+O1hJ6EEuqhD+dC9diK1CgpTUZAP2vTum4EwcsmqDWoB4LzY7AFETYbYp8LZEUvOBCYNtbh9N9Fov5Q+atRIq8Qjqq+Xn5x0wDREehw2aYnN12s5rStbcuvWL449hh/a0cdAOVFgqk5EgtDkk8jKErJyzVTPLjS6NBlg6tsmo0BS0JzdVLPTwV7rsACFVXuHIZGlXmbtBsOlK1u5AzbMu6BYQOfhrHGVSYmou5z3ZGJUDpf9wdBx8WB2deES/DZFSufuSQ5AcAzIA52vJ29E6CTqm6OLhAsJTiIDmAX6MSVvFsRFyQOBsIOTTpGn1YgEoij3eswIDAQAB\n"
// +"-----END RSA PUBLIC KEY-----"

// "public_key" : "-----BEGIN RSA PUBLIC KEY-----\n"
// + "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqce2Sf8MgdBGO4sv0yiOrBp\n"
// + "MkvV3pOVZGtuM4d0rQrA/\n"
// + "jXAS1JjSEaUauLJipM2X+JiziUMDgofKPPQKZakMfH3ZnDvV8TgOpQwJ61iI6xGhU+Y3D\n"
// + "TPP+BtvdyKvxeTbrQc5Et9LOhFzYCblXN3POdlrrpec4VsEQN9szuQfDRtDEqkNpgjanS5P\n"
// + "UKBluwdpkUPdAJuA17wRkYVJd5FsgZF6qmt/\n"
// + "GvooLrQMOU4C8yTPFUwYuDNiXXe520JA9adi4YD/\n"
// + "WXCkl8uB2vfZjXyqKcNqKoMi3Wm3yI128SBYX2hg/PYt6jO+DrvoxmQJm9iFi3aTWFhuy/\n"
// + "2nSY6DNyM/hwIDAQAB\n"
// + "-----END RSA PUBLIC KEY-----",

// "public_key":"-----BEGIN RSA PUBLIC KEY-----\n"
// +"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvlWX39E2k2CYRKGZFwlZLH\n"
// +"E7p139zkaQkCy4Yx1T70IGFtHIKBmPnjb9uzDmxTW1AihjO4iExYZh1OJz7/\n"
// +"PYbgjRufEJ28uzJaCAJGKJZaXtgrKqgLAgxJr0IHQ+KFtilqbrqecFO0aBdptxY3jvBblg2cfb/\n"
// +"AS5U3RmUwF6RzmJuTSeSK7sxnezheeubFusnxrop3iiopqJv9iGkVa97P3jAiF/\n"
// +"tVN1HqmsAHaiXobnOV2xXsNfHUJ0J8xW61Vpy7SvtEx0SLcVb/\n"
// +"kMaUstQNdIIvEeSxEmdmUHj3JakGWhFC79pQXP/\n"
// +"tDaeH71mVZJFHqO+Mfbm5+EtHU7OzfzKwIDAQAB\n"
// +"-----END RSA PUBLIC KEY-----"
 
}




// "public_key" : "-----BEGIN RSA PUBLIC KEY-----\n"
// +"MIIBCgKCAQEAqce2Sf8MgdBGO4sv0yiOrBpMkvV3pOVZGtuM4d0rQrA/jXAS1JjS\n"
// +"EaUauLJipM2X+JiziUMDgofKPPQKZakMfH3ZnDvV8TgOpQwJ61iI6xGhU+Y3DTPP+BtvdyKvxeTbrQc5Et9LOhFzYCblXN3POdlrrpec4VsEQN9szuQfDRtDEqkNpgja\n"
// +"nS5PUKBluwdpkUPdAJuA17wRkYVJd5FsgZF6qmt/GvooLrQMOU4C8yTPFUwYuDNi\n"
// +"XXe520JA9adi4YD/WXCkl8uB2vfZjXyqKcNqKoMi3Wm3yI128SBYX2hg/PYt6jO+\n"
// +"DrvoxmQJm9iFi3aTWFhuy/2nSY6DNyM/hwIDAQAB\n"
// +"-----END RSA PUBLIC KEY-----"
// }
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
